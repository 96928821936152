<!-- 404 -->
<template>
  <div></div>
</template>

<script>
export default {
  name: '',
  setup() {
    window.history.go(-1);
    return {};
  },
};
</script>
<style scoped></style>
